<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card rounded="lg" class="pa-5">
          <v-card-subtitle class="d-flex align-center justify-space-between">
            <div class="page-title mr-5">{{ $route.meta.title }}</div>
            <v-btn
              color="primary"
              class="px-6"
              depressed
              small
              :disabled="isFetching"
              :to="{
                name: 'courseCategoryCreate',
              }"
              >新增</v-btn
            >
          </v-card-subtitle>

          <v-card-text class="mt-5">
            <v-row>
              <DraggableTable
                :tableHeader="categoryHeader"
                :data="categoryData"
                @setOrder="setCategoryOrder"
                @dragTableAction="categoryAction"
              ></DraggableTable>
            </v-row>
            <v-row class="pt-10">
              <v-col cols="12">
                <v-btn
                  color="primary white--text"
                  class="px-6"
                  small
                  depressed
                  :disabled="isFetching"
                  @click="saveCourseCategoryOrder"
                >
                  儲存
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DraggableTable from '@/components/DraggableTable'

export default {
  name: 'courseCategory',
  components: {
    DraggableTable,
  },
  data() {
    return {
      categoryHeader: [
        { text: '標題', value: 'category_name', minWidth: '200px', width: '70%' },
        { text: '', value: 'viewBtn', width: '15%' },
        { text: '', value: 'deleteBtn', width: '15%' },
      ],
      categoryData: [],
      removeCategoryList: [],
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    ...mapActions(['setDialogMessage', 'setShowDialog']),

    async getCourseCategory() {
      try {
        const payload = {}

        const result = await this.$XHR.api('get_course_category', payload)
        this.$func.log('-----Get Course Category-----')
        this.$func.log(result)

        this.categoryData = result.data.map(obj => {
          return { id: obj.id, category_name: obj.category_name }
        })
      } catch (error) {
        this.$func.log('-----Get Course Category Fail-----')
        this.$func.log(error)
        this.categoryData = []
      } finally {
        this.$store.dispatch('setLoading', false)
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },

    setCategoryOrder(data) {
      this.categoryData = data
    },
    categoryAction(type, index) {
      if (type === 'view') {
        this.$router.push({
          name: 'courseCategoryDetail',
          params: {
            id: parseInt(this.categoryData[index].id),
          },
        })
      } else if (type === 'delete') {
        this.removeCategoryList.push(this.categoryData[index].id)
        this.categoryData.splice(index, 1)
      }
    },
    async saveCourseCategoryOrder() {
      if (this.categoryData.length > 0 || this.removeCategoryList.length > 0) {
        if (!this.isFetching) {
          this.setFetching(true)

          try {
            const payload = {
              verify_token: this.getAdminToken(),
              category_id_list: this.categoryData.map(obj => {
                return obj.id
              }),
              remove_id_list: this.removeCategoryList,
            }

            const result = await this.$XHR.api('cms_update_course_category_order', payload)
            this.$func.log('-----Update Course Category-----')
            this.$func.log(result)

            this.setDialogMessage({
              message: '儲存成功',
              isError: false,
              returnLink: null,
              refresh: true,
            })

            this.setShowDialog(true)
            this.setFetching(false)
          } catch (error) {
            this.$func.log('-----Update Course Category Fail-----')
            this.$func.log(error)

            let message = '儲存失敗'

            if (error.data === 'admin verification fail') {
              this.forceLogout()
            } else if (error.data === 'no permission') {
              message = '沒有權限'
            } else if (error.data === 'there are some course belongs to current category') {
              message = '部份課程仍在使用此分類'
            } else if (error.data === 'category not found') {
              message = '課程分類不存在'
            }

            this.setFetching(false)
            this.submitSuccess = false
            this.setDialogMessage({
              message: message,
              isError: true,
              returnLink: null,
            })
            this.setShowDialog(true)
          }
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('setLoading', true)
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      this.getCourseCategory()
    }
  },
}
</script>